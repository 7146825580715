import { HTTPMethod } from '@perpay-web/constants/httpMethods';
import { getData, useDataModule } from '@perpay-web/data-module/useDataModule';
import { useReduxDataModuleHookConnection } from '@perpay-web/data-module/useReduxDataModuleHookConnection';
import {
    validateAddressForCardMigration,
    validateAddressErrorForCardMigration,
    validateAddressResetForCardMigration,
    validateAddressSuccessForCardMigration,
} from '@perpay-web/fintech/actions/ui/addressValidation';
import { authenticatedJsonFetch } from '@perpay-web/fintech/utils/requestHandlers';
import { hookAction$ } from '@perpay-web/services/singletons';
import { ADDRESS_VALIDATION_ENDPOINT } from '@perpay-web/storefront/constants/urls';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const useValidateAddressForCardMigration = () => {
    const dataModule = useDataModule((payload) =>
        authenticatedJsonFetch(
            ADDRESS_VALIDATION_ENDPOINT,
            payload,
            HTTPMethod.post,
        ),
    );

    useReduxDataModuleHookConnection({
        dataModule,
        hookAction$,
        request: validateAddressForCardMigration,
        success: validateAddressSuccessForCardMigration,
        error: validateAddressErrorForCardMigration,
        reset: validateAddressResetForCardMigration,
    });

    return dataModule;
};

const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const dataModule = useValidateAddressForCardMigration();

        return (
            <Context.Provider value={dataModule}>{children}</Context.Provider>
        );
    };

    return Provider;
};
export const [
    UseValidateAddressForCardMigrationProvider,
    useValidateAddressForCardMigrationContext,
] = createContextProvider(providerFactory);

export const getAddressEntered = (state) =>
    getData(state) ? getData(state).originalAddress : null;

export const getAddressSuggested = (state) =>
    getData(state) ? getData(state).validatedAddress : null;

export const getUSPSAddressFound = (state) =>
    getData(state) ? getData(state).uspsAddressNotFound : false;
