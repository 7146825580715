import { createAction } from '@reduxjs/toolkit';

import { useModal } from '@perpay-web/components/base/Modal/useModal';
import { useActionObservableCallback } from '@perpay-web/hooks/useActionObservableCallback';
import { useSteps } from '@perpay-web/hooks/useSteps';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

export const INTRO_STEP = 'intro';
export const METAL_CARD_UPGRADE_STEP = 'metal-card-upgrade';
export const ADDRESS_COLLECTION_STEP = 'address-collection';
export const SUCCESS_STEP = 'success';
const CARD_MIGRATION_MODAL_STEPS = [
    INTRO_STEP,
    METAL_CARD_UPGRADE_STEP,
    ADDRESS_COLLECTION_STEP,
    SUCCESS_STEP,
];

export const cardMigrationAddressConfirmationModalSetIsModalOpen = createAction(
    'CARD_MIGRATION_ADDRESS_CONFIRMATION_MODAL::SET_IS_MODAL_OPEN',
);

const cardMigrationModalSetIsModalOpen = createAction(
    'CARD_MIGRATION_MODAL::SET_IS_MODAL_OPEN',
);

export const cardMigrationModalSetStep = createAction(
    'CARD_MIGRATION_MODAL::SET_STEP',
);

const useCardMigration = () => {
    const steps = useSteps(CARD_MIGRATION_MODAL_STEPS, INTRO_STEP);

    // USPS Address validation modal
    const {
        isModalOpen: isAddressValidationModalOpen,
        setIsModalOpen: setIsAddressValidationModalOpen,
    } = useModal();

    const {
        isModalOpen: isCardMigrationModalOpen,
        setIsModalOpen: setIsCardMigrationModalOpen,
    } = useModal();

    return {
        ...steps,
        isAddressValidationModalOpen,
        setIsAddressValidationModalOpen,
        isCardMigrationModalOpen,
        setIsCardMigrationModalOpen,
    };
};
const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const hook = useCardMigration();

        useActionObservableCallback(
            hookAction$,
            cardMigrationAddressConfirmationModalSetIsModalOpen().type,
            (value) => {
                hook.setIsAddressValidationModalOpen(value);
            },
        );

        useActionObservableCallback(
            hookAction$,
            cardMigrationModalSetIsModalOpen().type,
            (value) => {
                hook.setIsCardMigrationModalOpen(value);
            },
        );

        useActionObservableCallback(
            hookAction$,
            cardMigrationModalSetStep().type,
            (value) => {
                hook.setStep(value);
            },
        );

        return <Context.Provider value={hook}>{children}</Context.Provider>;
    };

    return Provider;
};

export const [CardMigrationProvider, useCardMigrationContext] =
    createContextProvider(providerFactory);
