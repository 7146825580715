import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchUserInfoForDataModule,
    fetchUserInfoSuccess,
    fetchUserInfoError,
    fetchUserInfoReset,
} from '@perpay-web/fintech/actions/entities/userInfo';

const FetchUserInfoDataModule = createDataModule({
    dataRequest: fetchUserInfoForDataModule,
    dataSuccess: fetchUserInfoSuccess,
    dataError: fetchUserInfoError,
    dataReset: fetchUserInfoReset,
});

const getRoot = (state) => state.dataModules.fetchUserInfo;
const initialValue = {};

export const fetchUserInfoDataModule = FetchUserInfoDataModule({
    getRoot,
    initialValue,
});

export const getFirstName = (state) =>
    fetchUserInfoDataModule.getData(state).firstName || '';

export const getLastName = (state) =>
    fetchUserInfoDataModule.getData(state).lastName || '';

export const getSsnIsVerified = (state) =>
    fetchUserInfoDataModule.getData(state).ssnIsVerified || false;

const getSsnVerificationAttempts = (state) =>
    fetchUserInfoDataModule.getData(state).ssnVerificationAttempts || 0;

export const getUserHasFailedIDV = (state) => {
    const ssnIsVerified = getSsnIsVerified(state);
    const ssnVerificationAttempts = getSsnVerificationAttempts(state);
    return !ssnIsVerified && ssnVerificationAttempts > 0;
};

export const getUserAffiliateGroupCodes = (state) =>
    fetchUserInfoDataModule.getData(state).affiliateGroupCodes || [];

export const getSsn = (state) =>
    fetchUserInfoDataModule.getData(state).ssn || '';

export const getVisibleSsn = (state) =>
    fetchUserInfoDataModule.getData(state).visibleSsn || '';

export const getDateOfBirth = (state) =>
    fetchUserInfoDataModule.getData(state).dateOfBirth || '';

export const getEmail = (state) => {
    const { email } = fetchUserInfoDataModule.getData(state);
    if (!email) {
        return '';
    }
    return email;
};

export const getIsExistingUserWithPassword = (state) => {
    const { signupRequirements } = fetchUserInfoDataModule.getData(state);
    if (!signupRequirements) {
        return false;
    }
    return !signupRequirements.includes('password_set');
};

export const getAddress = (state) => ({
    street1: fetchUserInfoDataModule.getData(state).streetAddr,
    street2: '',
    city: fetchUserInfoDataModule.getData(state).city,
    state: fetchUserInfoDataModule.getData(state).state,
    zipcode: fetchUserInfoDataModule.getData(state).zipcode,
});

export const getIsPhoneVerified = (state) =>
    fetchUserInfoDataModule.getData(state).phoneVerified || false;
